import { gql } from '@apollo/client';
import type { RichText } from 'src/types/RichText';

import type { MediaDataProps } from '../../../../components/Media';
import { assetFragment } from '../../../../components/Media';
import type { ContentfulSysProps, ContentfulTypedSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import type { HighlightProps } from '../Highlight/Highlight.types';
import { hightlightFragment } from '../Highlight/HighlightsQuery';
import type { StreamProps } from '../Stream/streamQuery';
import { streamFragment } from '../Stream/streamQuery';

// ============================================================================
// Event all fragment
// ============================================================================

export type LiveEventState = 'pre' | 'post' | 'live' | 'disruption';

export const liveEventFragment = gql`
  fragment LiveEventAll on LiveEvent {
    ...ContentfulSysId
    sys {
      publishedVersion
    }
    eventState
    isPolling
    pollingIntervalMs
    pageReloadReasons
    backupRedirectUrl
    streamPosterImage {
      ...AssetAll
    }
    stream {
      ...StreamAll
    }
    backupStream {
      ...StreamAll
    }
    vodStream {
      ...StreamAll
    }
    postEventOverviewText {
      json
    }
    highlightsSectionTitle
    highlightsCollection {
      items {
        ...HighlightAll
      }
    }
    postEventBlocksCollection {
      items {
        ...ContentfulSysId
      }
    }
    preEventBlocksCollection {
      items {
        ...ContentfulSysId
      }
    }
    disruptionBlocksCollection {
      items {
        ...ContentfulSysId
      }
    }
    logo {
      ...AssetAll
    }
    enableBitmojiReactions
    enableDarkMode
    enableBackupRedirect
    bufferStallTimeout
    analyticsId
    legacyStreamId
  }
  ${contentfulSysIdFragment}
  ${hightlightFragment}
  ${streamFragment}
  ${assetFragment}
`;

/** Event on the experience.snap.com website. We usually have one for each year and a few test ones. */
export interface LiveEventProps extends ContentfulTypedSysProps<'LiveEvent'> {
  sys: {
    id: string;
    publishedVersion: number;
  };
  eventState: LiveEventState;
  isPolling: boolean;
  pollingIntervalMs?: number;
  pageReloadReasons?: string;
  backupRedirectUrl?: string;
  highlightsSectionTitle?: string;
  highlightsCollection: Items<HighlightProps>;
  postEventBlocksCollection: Items<ContentfulSysProps>;
  streamPosterImage?: MediaDataProps;
  stream?: StreamProps;
  backupStream?: StreamProps;
  vodStream?: StreamProps;
  postEventOverviewText?: RichText;
  preEventBlocksCollection: Items<ContentfulSysProps>;
  disruptionBlocksCollection: Items<ContentfulSysProps>;
  logo?: MediaDataProps;
  enableBitmojiReactions?: boolean;
  enableDarkMode?: boolean;
  enableBackupRedirect?: boolean;
  bufferStallTimeout?: number;
  analyticsId: string;
  legacyStreamId?: string;
}

// ============================================================================
// Event polled content fragment (used for state transition)
// ============================================================================

export const liveEventRealtimeFragment = gql`
  fragment LiveEventRealtime on LiveEvent {
    ...ContentfulSysId
    sys {
      publishedVersion
      publishedAt
    }
    eventState
    isPolling
    pollingIntervalMs
    pageReloadReasons
    enableBackupRedirect
    backupRedirectUrl
    enableBitmojiReactions
  }
  ${contentfulSysIdFragment}
`;

export type LiveEventRealtimeProps = Pick<
  LiveEventProps,
  | 'eventState'
  | 'isPolling'
  | 'pollingIntervalMs'
  | 'pageReloadReasons'
  | 'enableBackupRedirect'
  | 'backupRedirectUrl'
  | 'enableBitmojiReactions'
> & {
  sys: {
    id: string;
    publishedVersion?: number;
    updatedAt?: string; // only in webhook events
    publishedAt?: string; // not in publish (prod) webhook events :(
  };
};

// ============================================================================
// Event all query
// ============================================================================

export const liveEventQuery = gql`
  query LiveEventQuery($id: String!, $preview: Boolean!, $locale: String!) {
    liveEvent(id: $id, preview: $preview, locale: $locale) {
      ...LiveEventAll
    }
  }
  ${liveEventFragment}
`;

export interface LiveEventDataProps {
  liveEvent: LiveEventProps;
}

// ============================================================================
// Event polled content query
// ============================================================================

export const liveEventRealtimeQuery = gql`
  query LiveEventRealtimeQuery($id: String!, $preview: Boolean!, $locale: String!) {
    liveEvent(id: $id, preview: $preview, locale: $locale) {
      ...LiveEventRealtime
    }
  }
  ${liveEventRealtimeFragment}
`;

export interface LiveEventRealtimeDataProps {
  liveEvent?: LiveEventRealtimeProps;
}
