import { ClientBrowserFeature } from '@snapchat/client-hints-browser';

import { Config } from '../../../../config';
import type { AkamaiMediaConfiguration, AkamaiTrackConfiguration } from '../../../../types/akamai';
import type { Items } from '../../../../types/Items';
import type { CaptionsData } from '../Captions/captionsQuery';

const convertToAkamaiTrackConfiguration = (
  captions?: Items<CaptionsData>
): AkamaiTrackConfiguration[] | undefined => {
  if (!captions?.items?.length) return undefined;

  return captions.items.map(({ kind, sourceLanguage, sourceUrl }) => {
    const trackConfiguration: AkamaiTrackConfiguration = {
      kind: kind ?? 'subtitles', // default to 'subtitles' if not specified (matches Contentful field documentation)
      src: sourceUrl,
      // TODO: revert the iOS safari specific logic once vendor resolves issue (ENTWEB-7623)
      // Per Akamai support, use a different value for iOS devices until they have a permanent fix in place.
      type: isIosDevice() ? 'text/cea-608' : 'text/vtt',
      srclang: sourceLanguage,
    };

    return trackConfiguration;
  });
};

/**
 * Parse userAgent to determine whether client is an iOS device.
 *
 * For simplicity, made this a noop for SSR. This function is ONLY used for part of a vendor
 * specified workaround for this browser DO NOT USE except for this one use case. TODO: remove this
 * once the workaround is no longer needed (ENTWEB-7623).
 */
const isIosDevice = () => {
  if (!Config.isClient) return false;
  // TODO: This should be done inside of a react component and passing in existing browser props.
  const browserFeatures = new ClientBrowserFeature();
  return browserFeatures.getLowEntropyHints().platform === 'iOS';
};

const getMediaType = (videoUrl: string): string => {
  const mp4Pattern = /.mp4$/;
  const isMp4 = mp4Pattern.test(videoUrl);

  return isMp4 ? 'video/mp4' : 'application/x-mpegURL';
};

type MediaConfigurationOptions = Required<Pick<AkamaiMediaConfiguration, 'temporalType'>>;

/** Converts settings in Contentful to the AkamaiMediaConfiguration format. */
export const buildMediaConfiguration = (
  title: string,
  videoUrl: string,
  captions: Items<CaptionsData> | undefined,
  posterUrl: string | undefined,
  options: MediaConfigurationOptions
): AkamaiMediaConfiguration => {
  const convertedCaptions = convertToAkamaiTrackConfiguration(captions);
  const type = getMediaType(videoUrl);

  // These are default values from Akamai. If we don't set them then akamai will set these anyway
  // by overwriting the undefined value. They are here for visibility.
  const defaultOptions = {
    status: {},
    restriction: [],
    metadata: {},
  };

  return {
    title,
    poster: posterUrl,
    source: [
      {
        src: videoUrl,
        type,
      },
    ],
    track: convertedCaptions,
    medium: 'video',
    // If the video is live, the startTime is always 0 to be current with the stream
    startTime: options.temporalType === 'live' ? 0 : undefined,
    ...defaultOptions,
    ...options,
  };
};
