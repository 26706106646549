import { useImperativeEffect } from '@snapchat/core-browser';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import { type FC, useContext } from 'react';

import { BlockShallow } from '../../../../components/Block/BlockShallow';
import { PageLayoutContext } from '../../../../context/PageLayoutContext';
import type { ContentfulSysProps } from '../../../../types/contentful';
import type { LiveEventProps } from './liveEventQuery';

type Props = Pick<LiveEventProps, 'disruptionBlocksCollection'>;

export const DisruptionState: FC<Props> = props => {
  const { disruptionBlocksCollection } = props;
  const { setHeaderBackgroundColorOverride } = useContext(PageLayoutContext);

  // Header background may have been dynamically changed to black if dark mode is enabled.
  // Ensure it is reset to white on the "disruption" page.
  useImperativeEffect(() => {
    setHeaderBackgroundColorOverride?.(BackgroundColor.White);
  }, [setHeaderBackgroundColorOverride]);

  return (
    <>
      {disruptionBlocksCollection.items.map((props: ContentfulSysProps, index: number) => {
        return <BlockShallow key={`${props.__typename}_${index}`} {...props} />;
      })}
    </>
  );
};
