import type { ClientBroadcastNotificaton, ContentfulNotification } from '@snapchat/mw-common';
import { getContentfulWebhookEventKey } from '@snapchat/mw-common';
import { ContentfulAlias } from '@snapchat/mw-contentful-schema';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';

import { AppContext } from '../../../../AppContext';
import { ConsumerContext } from '../../../../components/ConsumerContextProvider';
import { IntoDebugPortal } from '../../../../components/DebugPortal/DebugPortal';
import { Config } from '../../../../config';
import { UrlParameter } from '../../../../constants/urlParameters';
import { defaultLocale } from '../../../../helpers/locale';
import { logInfo, logTiming } from '../../../../helpers/logging';
import { useBroadcast } from '../../../../hooks/useBroadcast';
import type { ContentfulIdVariable } from '../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../hooks/useContentfulQuery';
import { BitmojiContextProvider } from '../BitmojiProvider';
import { Toast } from '../Toast/Toast';
import { DisruptionState } from './DisruptionState';
import type {
  LiveEventDataProps,
  LiveEventRealtimeDataProps,
  LiveEventRealtimeProps,
  LiveEventState,
} from './liveEventQuery';
import { liveEventQuery, liveEventRealtimeQuery } from './liveEventQuery';
import { LiveState } from './LiveState';
import { PostState } from './PostState';
import { PreState } from './PreState';

/**
 * Switch for pre/post/live state. Listens for updates.
 *
 * Allows setting the state via URL override (eventState parameter).
 */
export const EventRoot: FC<{ id: string }> = props => {
  // ==========================================================================
  // URL State Override
  // ==========================================================================

  const { getCurrentUrl } = useContext(AppContext);
  const { isUrlCurrent } = useContext(ConsumerContext);
  const url = new URL(getCurrentUrl());

  const [showReloadToast, setShowReloadToast] = useState(false);

  let eventStateOverride: LiveEventState | undefined = url.searchParams.get(
    UrlParameter.EXPERIENCE_EVENT_STATE
  ) as LiveEventState;

  const disableWebSockets =
    url.searchParams.get(UrlParameter.EXPERIENCE_DISABLE_WEB_SOCKETS) === 'true';

  if (Config.isDeploymentTypeProd && eventStateOverride === 'post') {
    eventStateOverride = undefined;
  }

  // Retrieves all event data.
  const { data, refetch } = useContentfulQuery<LiveEventDataProps, ContentfulIdVariable>(
    liveEventQuery,
    { variables: { id: props.id } }
  );

  // ==========================================================================
  // Realtime fields reducer.
  // ==========================================================================

  const initialRealtimeData: LiveEventRealtimeProps | undefined = data
    ? {
        eventState: data.liveEvent?.eventState,
        isPolling: data.liveEvent?.isPolling,
        enableBitmojiReactions: data.liveEvent?.enableBitmojiReactions,
        enableBackupRedirect: data.liveEvent?.enableBackupRedirect,
        pollingIntervalMs: data.liveEvent?.pollingIntervalMs,
        pageReloadReasons: data.liveEvent?.pageReloadReasons,
        sys: data.liveEvent?.sys,
      }
    : undefined;

  const [realtimeData, updateRealtimeData] = useReducer(
    (state: LiveEventRealtimeProps | undefined, action: LiveEventRealtimeProps) => {
      // Do nothing if we receive a stale version.
      // There are 4 streams for receiving these events:
      //  1. Initial data
      //  2. Polled data
      //  3. Refetch of the initial data
      //  4. Message on broadcast websocket.
      // Staleness might happen if websocket disconnects and Fastly (contentful cache) returns a stale
      // response (has happened in testing).

      // Webhooks have updatedAt, graphql query has publishedAt. No other way to do this.
      // Limitation: polling will get publishedAt. In staging unless you actually published this
      // doesnt actually get updated. So polling in staging cannot support unpublished content.
      const actionUpdatedAt = action.sys.updatedAt ?? action.sys.publishedAt;

      // TODO see if we can get contentful to have publishedVersion or version for all events so we can use it again.
      if ((actionUpdatedAt ?? '') < (state?.sys!.updatedAt ?? '')) {
        return state;
      }

      if (!action) return;

      // Trim off extra fields, so we can compare stuff. Fields must match LiveEventRealtimeProps
      // NOTE: Not using the pick helper function here to avoid doing nested picks for the sys props.
      action = {
        sys: {
          id: action.sys.id,
          publishedVersion: action.sys.publishedVersion,
          updatedAt: actionUpdatedAt,
          publishedAt: action.sys.publishedAt,
        },
        eventState: action.eventState,
        isPolling: action.isPolling,
        pollingIntervalMs: action.pollingIntervalMs,
        pageReloadReasons: action.pageReloadReasons,
        enableBitmojiReactions: action.enableBitmojiReactions,
        enableBackupRedirect: action.enableBackupRedirect,
        backupRedirectUrl: action.backupRedirectUrl,
      };

      // Overwrite if no current state.
      if (!state) return action;

      // Do nothing if action is the same (happens on every re-render).
      if (isEqual(state, action)) return state;

      // Merge if they are different
      return { ...state, ...action };
    },
    initialRealtimeData
  );

  // ==========================================================================
  // Contentful query (initial and refetch).
  // ==========================================================================

  // When re-fetch is called, this is where new data ends up.
  useEffect(() => {
    if (!data?.liveEvent) return;

    updateRealtimeData(data?.liveEvent);
    // We need to do a deep comparison of the data object because it has nested arrays and objects as some of its properties.
    // Even when these nested properties do not change, useEffect will compare these by reference and result in infinite
    // re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  // When the event state changes, we refetch the entire event.
  useEffect(() => {
    void refetch();
  }, [refetch, realtimeData?.eventState]);

  // ==========================================================================
  // Realtime fields polling (backup to websockets).
  // ==========================================================================

  const [shouldPoll, setShouldPoll] = useState<boolean | undefined>(false);

  const { data: polledRealtimeData } = useContentfulQuery<
    LiveEventRealtimeDataProps,
    ContentfulIdVariable
  >(liveEventRealtimeQuery, {
    variables: { id: props.id },
    skip: !shouldPoll, // Should skip if value not defined.
    pollInterval: realtimeData?.pollingIntervalMs ?? undefined,
  });

  useEffect(() => {
    polledRealtimeData?.liveEvent && updateRealtimeData(polledRealtimeData?.liveEvent);
  }, [polledRealtimeData]);

  // ==========================================================================
  // Realtime fields from web sockets.
  // ==========================================================================

  const onRealtimeEventUpdate = useCallback((notification: ClientBroadcastNotificaton) => {
    const event = notification as ContentfulNotification; // Know the type from the subscription key

    function getValue<Key extends keyof LiveEventRealtimeProps>(
      key: Key
    ): LiveEventRealtimeProps[Key] {
      // NOTE: This retrieves english values only. When we want to support translations
      // we need to query current locale and manually fallback to English.
      return get(event.fields, [key, defaultLocale]) as LiveEventRealtimeProps[Key];
    }

    logTiming({
      eventVariable: 'contentful_event_delivery',
      eventValue: Date.now() - new Date(event.sys.updatedAt).getTime(),
      eventCategory: 'LiveEvent',
    });

    updateRealtimeData({
      sys: {
        id: event.sys.id,
        publishedVersion: event.sys.version,
        updatedAt: event.sys.updatedAt,
      },
      eventState: getValue('eventState'),
      isPolling: getValue('isPolling'),
      pollingIntervalMs: getValue('pollingIntervalMs'),
      pageReloadReasons: getValue('pageReloadReasons'),
      backupRedirectUrl: getValue('backupRedirectUrl'),
      enableBackupRedirect: getValue('enableBackupRedirect'),
      enableBitmojiReactions: getValue('enableBitmojiReactions'),
    });
  }, []);

  // When the websocket disconnects or encounters an error, we start polling.
  const startPolling = useCallback(() => {
    setShouldPoll(true);
  }, []);

  // We stop polling if the websocket is running.
  const stopPolling = useCallback(() => {
    setShouldPoll(false);

    // Per Brenan's request, we try to get the latest data directly from contentful with a
    // delay after a reconnect to pick up any changes that might have happened while the
    // socket was disconnected.
    setTimeout(refetch, 5e3);
  }, [refetch]);

  const broadcastEventKey = getContentfulWebhookEventKey(
    Config.contentful.spaceId,
    ContentfulAlias.MASTER, // We only fire events in master so can only listen to this.
    data?.liveEvent?.sys.id
  );

  // Setting the key to undefined is like skipping setting up sockets.
  useBroadcast(realtimeData?.isPolling && !disableWebSockets ? broadcastEventKey : undefined, {
    onMessage: onRealtimeEventUpdate,
    onError: startPolling,
    onDisconnect: startPolling,
    onReconnect: stopPolling,
  });

  // ==========================================================================
  // Logging the current event state.
  // ==========================================================================

  /** Atomic reference to a function that will trigger the current state update. */
  const logCurrentStateRef = useRef<() => void>();

  const logCurrentStateFn = useCallback(() => {
    if (!data?.liveEvent || !realtimeData) return;

    logInfo({
      eventAction: 'EventState',
      eventCategory: 'LiveEvent',
      eventLabel: `${data.liveEvent.analyticsId}:${realtimeData.eventState}`,
    });
  }, [data?.liveEvent, realtimeData]);

  // Updates the ref to the latest state record.
  useEffect(() => {
    logCurrentStateRef.current = logCurrentStateFn;
  }, [logCurrentStateFn]);

  // Heartbeat every 10 seconds after initial page load or event state change
  // so we have visibility into what page state users are experiencing.
  useEffect(() => {
    logCurrentStateRef.current?.(); // Logs initial state if possible.
    const intervalRef = setInterval(() => logCurrentStateRef.current?.(), 10e3);

    return () => {
      clearInterval(intervalRef);
    };
  }, [logCurrentStateRef]);

  // ==========================================================================
  // Handle page reloads.
  // ==========================================================================

  const [isReloading, setIsReloading] = useState<boolean>(false);

  /**
   * Durating for which to display a notification about reload for before reloading, randomized
   * between 8-12 seconds.
   */
  const displayReloadToastMsRef = useRef(Math.floor(Math.random() * 4e3) + 8e3);

  useEffect(() => {
    if (isReloading || !isUrlCurrent) return;

    const latestReloadReason = realtimeData?.pageReloadReasons;
    const initialReloadReason = data?.liveEvent?.pageReloadReasons;

    if (
      !isEmpty(latestReloadReason) &&
      latestReloadReason !== initialReloadReason &&
      // If there's no redirect URL or if there is a redirect URL but it's not the same as current URL
      (!realtimeData?.backupRedirectUrl || !isUrlCurrent(realtimeData?.backupRedirectUrl))
    ) {
      setIsReloading(true);
      setShowReloadToast(true);

      // NOTE: Logging here rather than deferring to when toast is closed to give the
      // logging frameworks time to send the message before the page is reloaded.
      logInfo({
        eventCategory: 'LiveEvent',
        eventAction: 'Reloading Page',
        eventLabel: `${data?.liveEvent.analyticsId}:reload`,
      });
    }
  }, [
    data?.liveEvent?.pageReloadReasons,
    data?.liveEvent.analyticsId,
    isReloading,
    realtimeData?.pageReloadReasons,
    realtimeData?.backupRedirectUrl,
    isUrlCurrent,
  ]);

  // If the backup redirect feature flag is enabled, and we are not on the backup URL already, redirect to backup URL
  useEffect(() => {
    if (
      !realtimeData?.enableBackupRedirect ||
      !realtimeData?.backupRedirectUrl ||
      !isUrlCurrent ||
      isUrlCurrent(realtimeData.backupRedirectUrl)
    ) {
      return;
    }

    // Don't use redirectTo or any other routing functions that are based on react-router.
    window.location.replace(realtimeData.backupRedirectUrl);
  }, [realtimeData, realtimeData?.enableBackupRedirect, isUrlCurrent]);

  const toastReloadCallback = useCallback(() => {
    if (!realtimeData?.backupRedirectUrl) {
      window.location.reload();
      return;
    }

    // Don't use redirectTo or any other routing functions that are based on react-router.
    window.location.replace(realtimeData.backupRedirectUrl);
  }, [realtimeData?.backupRedirectUrl]);

  // ==========================================================================
  // Component render logic.
  // ==========================================================================

  if (!realtimeData) return null;

  function renderState(eventState: LiveEventState) {
    switch (eventState) {
      case 'pre': {
        return <PreState {...data!.liveEvent} />;
      }

      case 'live': {
        return (
          <LiveState
            {...data!.liveEvent}
            enableBitmojiReactions={realtimeData?.enableBitmojiReactions}
          />
        );
      }

      case 'post': {
        return (
          <PostState
            {...data!.liveEvent}
            enableBitmojiReactions={realtimeData?.enableBitmojiReactions}
          />
        );
      }

      case 'disruption': {
        return <DisruptionState {...data!.liveEvent} />;
      }
    }
  }

  return (
    <BitmojiContextProvider>
      <Toast
        open={showReloadToast}
        onClose={() => setShowReloadToast(false)}
        onCloseTransitionOut={toastReloadCallback}
        autoCloseTimeMs={displayReloadToastMsRef.current}
      >
        {realtimeData.pageReloadReasons}
      </Toast>
      <section
        data-time={realtimeData.sys.updatedAt}
        data-value={realtimeData.eventState}
        data-testid="live-event"
      >
        {renderState(eventStateOverride ?? realtimeData.eventState)}
      </section>
      <IntoDebugPortal name="live-event-debug">
        <>
          Event Updated: {realtimeData.sys.updatedAt ?? 'N/A'}
          <br />
          Event State: {realtimeData.eventState}
          <br />
          Is Realtime: {realtimeData.isPolling ? 'YES' : 'NO'}
          <br />
          Is Polling: {shouldPoll ? 'YES' : 'NO'}
        </>
      </IntoDebugPortal>
    </BitmojiContextProvider>
  );
};
